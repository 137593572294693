//source for hexToRgbA https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
const hexToRgbA = (hex, alpha = 1) => {
  if (!hex) {
    return hex;
  }
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1)?.split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ',',
    )} , ${alpha})`;
  }
};

export const getLinearGradient = (
  color: string,
  secondColorStart = 40,
  firstColorStart = 0,
): string => {
  return `linear-gradient(to bottom, ${hexToRgbA(
    color,
    0.13,
  )} ${firstColorStart}%, rgb(255, 255, 255) ${secondColorStart}%)`;
};

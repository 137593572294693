/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import '@sprinklr/shared-lib/assets/styles/scaffolding.css';
import './src/styles/globals.css';

import inEU from '@segment/in-eu';
import { Page } from './src/components/Page';
import RootWrapper from './src/components/RootWrapper';

export const onClientEntry = () => {
  // set default cookie settings
  document.cookie = `initial-preferences=${JSON.stringify(
    inEU()
      ? {
          functional: true,
          marketingAndAnalytics: false,
          advertising: false,
        }
      : {
          functional: true,
          marketingAndAnalytics: true,
          advertising: true,
        },
  )}`;
};

export const onRouteUpdate = () => {
  if (window.dataLayer) {
    setTimeout(() => {
      window.dataLayer.push({
        event: 'gatsby-route-change',
      });
    }, 50);
  }
};

export const wrapPageElement = ({ element, props }) => {
  return <Page {...props} element={element} />;
};

export const wrapRootElement = ({ element, ...rest }) => {
  return <RootWrapper element={element} />;
};

export const isInViewport = (el, breakpointIndex) => {
  const rect = el?.current?.getBoundingClientRect();
  if (!rect) return;

  let topVal;
  if (breakpointIndex === 0) topVal = 76;
  if (breakpointIndex >= 1) topVal = 135;

  return (
    rect.top >= topVal &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

import { base } from '@theme-ui/presets';
import { toTheme } from '@theme-ui/typography';
import { merge, Theme } from 'theme-ui';
import noriega from 'typography-theme-noriega';

const baseTheme = merge(base, toTheme(noriega));

// Note: we can add proper types for the extras like 'container' at some point
export const extendedTheme: Theme & { [key: string]: any } = merge(baseTheme, {
  breakpoints: ['40em', '52em', '64em', '1250px'],
  space: [
    0, // 0
    4, // 1
    8, // 2
    16, // 3
    32, // 4
    64, // 5
    128, // 6
    256, // 7
    512, // 8
  ],
  container: {
    none: 0,
    xs: 22,
    sm: 40,
    md: 80,
    lg: 160,
  },
  fonts: {
    body: '"proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  fontSizes: [
    12, //0
    14, //1
    16, //2
    18, //3
    20, //4
    22, //5
    24, //6
    26, //7
    28, //8
    30, //9
    32, //10
    34, //11
    36, //12
    38, //13
    40, //14
    43, //15
    50, //16
    56, //17
  ],
  radii: [3, 10, 15, 20, 30, '50%'],
  fontWeights: {
    regular: 400,
    body: 400,
    normal: 400,
    medium: 500,
    heading: 500,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.125,
    blogHeading: 1.25,
  },
  colors: {
    text: '#000',
    textSecondary: '#fff',
    textMuted: '#939393',
    textMedium: '#48514c',
    textMediumLight: '#636363',
    background: '#fff',
    primary: '#118ACB',
    secondary: '#36647D',
    muted: '#F5F5F5',
    disabled: '#D2E2FF',
    marketing: '#33cc99',
    advertising: '#d149a6',
    research: '#ecad00',
    ai: '#6d7278',
    care: '#8c43ff',
    engagement: '#2ea2fb',
    cornflowerBlue: '#7097eb',
    star: '#f7b500',
    grey: '#6d7278',
    softBorder: '#e6e9f9',
    pageType: '#9B9B9B',
    caption: '#9B9B9B',
    extraLightGrey: '#F5F5F7',
    lightGrey: '#DBDBDB',
    mediumGrey: '#A0A0A8',
    darkGrey: '#646470',
    formFieldGrey: '#F1F1F1',
    success: '#6AC96E',
    error: '#FF6060',
    spaceGrey: '#2E3841',
    skyBlue: '#00BAE9',
    oceanBlue: '#118ACB',
    slateBlue: '#36647D',
    sprText02: '#434350',
    lightGreyBorder: '#eee',
    mediumGreyBorder: '#aaa',
    tableBorder: '#C2C2C2',
    dropdownBorder: '#999999',
    dropdownLightGrey: '#808080',
    dropdownDarkGrey: '#666666',
    dropdownHoverColor: '#EFEFEF',
    backgroundGlass: '#ffffff99',
    modes: {
      hyperspace: {
        text: '#fff',
        background: '#1F1F1F',
        pageType: '#fff',
      },
    },
  },
  shadows: [
    '0px 2px 10px 0px rgba(0, 0, 0, 0.2)',
    '10px 10px 30px 10px rgba(0,0,0,0.2)',
    '0 0 20px 10px rgba(0,0,0,0.2)',
    '0px 2px 30px 0px rgba(0, 0, 0, 0.11)',
    '0px 2px 30px 0px rgba(0, 0, 0, 0.11)',
    '0px 2px 20px 0px rgba(0, 0, 0, 0.11)',
    '0px 4px 20px 0px rgba(0 0 0 / 20%)',
    '0px 8px 14px 0px rgba(0, 0, 0, 0.1)',
    '0px 2px 5px rgba(0, 0, 0, 0.1)',
  ],
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'regular',
      pre: {
        margin: 0,
      },
      body: {
        margin: 0,
      },
    },
    h1: {
      fontFamily: 'body',
      lineHeight: 1.3,
      fontWeight: 'bold',
      fontSize: [7, 15],
    },
    h2: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 1.3,
      fontWeight: 'bold',
      fontSize: [5, 12],
    },
    h3: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 1.4,
      fontWeight: 'bold',
      fontSize: [4, 10],
    },
    h4: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 1.4,
      fontWeight: 'bold',
      fontSize: [4, 8],
    },
    h5: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 1.4,
      fontWeight: 'bold',
      fontSize: [3, 6],
    },
    h6: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 1.4,
      fontWeight: 'bold',
      fontSize: [3, 4],
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 1.6,
      fontWeight: 'regular',
      fontSize: [2, 3, 4, 4],
    },
    a: {
      fontWeight: 'bold',
      color: 'primary',
      textDecoration: 'underline',
      transition: '.2s color',
      ':hover': {
        transition: '.2s color',
        color: 'secondary',
      },
    },
    pre: {
      fontFamily: 'body',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'body',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    strong: {
      fontFamily: 'body',
    },
    img: {
      maxWidth: '100%',
      marginBottom: 3,
    },
    blockquote: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: [4, 5],
      marginBottom: [4, 5],
      marginLeft: '0',
      marginRight: '0',
      padding: ['30px', '50px'],
      backgroundColor: 'muted',
      borderRadius: [0, 1],
      p: {
        textAlign: 'center',
        fontSize: [2, 3, 4, 10],
        lineHeight: 'body',
        margin: '0',
        maxWidth: '820px',
        strong: {
          fontSize: 'unset',
          lineHeight: 'unset',
        },
        code: {
          display: 'block',
          fontFamily: 'body',
          fontSize: [2],
          lineHeight: 'body',
        },
      },
    },
    hr: {
      margin: '0 auto',
      marginBottom: [5, 5, 5],
      width: '150px',
    },
    ul: {
      paddingLeft: 'revert',
      color: 'text',
      lineHeight: 1.6,
      fontWeight: 'regular',
      fontSize: [2, 3, 4, 4],
    },
    ol: {
      paddingLeft: 'revert',
      color: 'text',
      lineHeight: 1.6,
      fontWeight: 'regular',
      fontSize: [2, 3, 4, 4],
    },
  },
  // variants
  buttons: {
    primary: {
      color: '#fff',
      bg: 'primary',
      borderWidth: '2px',
      borderColor: 'primary',
      borderStyle: 'solid',
      transition: '.2s background, .2s border-color',
      fontSize: [0, '15px'],
      fontWeight: 'bold',
      fontFamily: 'body',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['4px 20px', '8px 34px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      '&:hover': {
        transition: '.2s background, .2s border-color',
        bg: 'secondary',
        borderColor: 'secondary',
      },
    },
    // Must manually update in SegmentConsent.tsx
    primarySmall: {
      color: '#fff',
      bg: 'primary',
      transition: '.2s background, .2s border-color',
      fontSize: '12px',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      fontFamily: 'body',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['5px 14px 3px', '6px 20px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      '&:hover': {
        transition: '.2s background',
        bg: 'secondary',
      },
    },
    secondary: {
      color: 'primary',
      backgroundColor: 'transparent',
      borderWidth: '2px',
      fontFamily: 'body',
      borderColor: 'primary',
      borderStyle: 'solid',
      transition: '.2s color, .2s background-color, .2s border-color',
      fontSize: [0, '15px'],
      fontWeight: 'bold',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['4px 20px', '8px 34px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      path: {
        fill: 'primary',
        transition: '.2s fill',
      },
      '&:hover': {
        transition: '.2s color, .2s background-color, .2s border-color',
        backgroundColor: 'secondary',
        borderColor: 'secondary',
        color: 'textSecondary',
        path: {
          fill: 'textSecondary',
          transition: '.2s fill',
        },
      },
    },
    secondarySmall: {
      color: 'primary',
      backgroundColor: 'transparent',
      borderWidth: '2px',
      fontFamily: 'body',
      borderColor: 'primary',
      borderStyle: 'solid',
      transition: '.2s color, .2s background-color, .2s border-color',
      fontSize: '12px',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['5px 14px 3px', '4px 18px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      path: {
        fill: 'primary',
        transition: '.2s fill',
      },
      '&:hover': {
        transition: '.2s color, .2s background-color, .2s border-color',
        backgroundColor: 'secondary',
        borderColor: 'secondary',
        color: 'textSecondary',
        path: {
          fill: 'textSecondary',
          transition: '.2s fill',
        },
      },
    },
    lightPrimary: {
      color: '#000',
      bg: '#fff',
      borderWidth: 0,
      fontFamily: 'body',
      transition: '.2s color, .2s background',
      fontSize: [0, '15px'],
      fontWeight: 'bold',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['6px 20px', '10px 34px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      '&:hover': {
        transition: '.2s color, .2s background',
        color: '#fff',
        bg: 'rgba(255, 255, 255, .5)',
      },
    },
    lightPrimarySmall: {
      color: '#000',
      bg: '#fff',
      fontFamily: 'body',
      transition: '.2s background, .2s border-color',
      fontSize: '12px',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['5px 14px 3px', '6px 20px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      '&:hover': {
        transition: '.2s color, .2s background',
        color: '#fff',
        bg: 'rgba(255, 255, 255, .5)',
      },
    },
    lightSecondary: {
      color: '#fff',
      bg: 'transparent',
      borderWidth: '2px',
      borderColor: '#fff',
      borderStyle: 'solid',
      fontFamily: 'body',
      transition: '.2s color, .2s background',
      fontSize: [0, '15px'],
      fontWeight: 'bold',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['4px 20px', '8px 34px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      '&:hover': {
        transition: '.2s color, .2s background',
        color: '#000',
        bg: '#fff',
      },
    },
    // Must manually update in SegmentConsent.tsx
    lightSecondarySmall: {
      color: '#fff',
      bg: 'transparent',
      borderWidth: '2px',
      borderColor: '#fff',
      fontFamily: 'body',
      borderStyle: 'solid',
      transition: '.2s background, .2s border-color',
      fontSize: '12px',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['5px 14px 3px', '6px 20px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      '&:hover': {
        transition: '.2s color, .2s background',
        color: '#000',
        bg: '#fff',
      },
    },
    darkPrimary: {
      color: '#fff',
      backgroundColor: '#000',
      borderWidth: '2px',
      borderColor: '#000',
      borderStyle: 'solid',
      fontFamily: 'body',
      transition: '.2s color, .2s background-color, .2s border-color',
      fontSize: [0, '15px'],
      fontWeight: 'bold',
      lineHeight: ['24px', '24px', '24px', '24px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: ['4px 20px', '8px 34px'],
      borderRadius: '50px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      '&:hover': {
        transition: '.2s color, .2s background-color, .2s border-color',
        backgroundColor: '#fff',
        borderColor: '#000',
        color: '#000',
      },
    },
  },
  // TODO: Audit if these styles do anything anymore after updating with hubspot form
  forms: {
    label: {
      fontWeight: 'bold',
      color: '#888888',
      fontSize: 1,
      lineHeight: '18px',
    },
    input: {
      color: 'text',
      fontSize: 2,
      px: 3,
      height: [45, 55],
      letterSpacing: 1,
      borderColor: 'grey',
      bg: 'background',
      '::placeholder': {
        fontSize: 2,
      },
      ':focus': {
        outline: 'none',
        borderColor: 'primary',
      },
    },
    select: {
      borderRadius: '4px',
      border: 'none',
      p: '12px 30px 12px 16px',
      appearance: 'none',
      background: '#F1F1F1',
      color: 'darkGrey',
      fontSize: 1,
    },
    textarea: {
      px: 3,
      bg: 'background',
      borderColor: 'grey',
      borderRadius: 1,
      '&:focus': {
        outline: 'none',
        borderColor: 'primary',
      },
    },
  },
  spinner: {
    white: {
      color: '#fff',
    },
    black: {
      color: '#000',
    },
  },
} as Theme & { [key: string]: any });

export default extendedTheme;

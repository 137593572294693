export const richTextMarkDownParser = richTextCodeMark => {
  const pseudoMarkDown = richTextCodeMark[1];
  const textOnly = pseudoMarkDown.replace(/[^a-zA-Z ]/g, '');

  const regEx = /\[(.*?)\]\((.*?)\)/gi;
  const parsedPseudoMarkDown = regEx.exec(pseudoMarkDown);

  if (!parsedPseudoMarkDown || parsedPseudoMarkDown.length <= 1)
    return { string: textOnly };

  return {
    fullMatch: parsedPseudoMarkDown[0],
    string: parsedPseudoMarkDown[1],
    accentTheme: parsedPseudoMarkDown[2],
  };
};

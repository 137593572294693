const durationFromWordCount = (
  wordCount: number,
  averageWordsPerMinute = 200,
) => {
  return wordCount ? Math.ceil(wordCount / averageWordsPerMinute) : 1;
};

export const minutesToRead = (content: string, type = 'text'): number => {
  let textLength = 0;

  if (!content) return 0;

  if (type === 'richTextJson') {
    const onlyReadableNodeTypes = ['paragraph', 'heading'];

    try {
      const richTextJson = JSON.parse(content);

      const wordCountOnlyReadables = richTextJson.content.map(
        (onlyReadable: { nodeType: string[][]; content: any[] }) => {
          if (!~onlyReadable.nodeType.indexOf(onlyReadableNodeTypes)) {
            return onlyReadable.content.reduce(
              (accumulator: number, currentValue: any) => {
                let wordCountOfCurrVal = 0;

                if (currentValue.nodeType === 'text') {
                  wordCountOfCurrVal = currentValue.value?.split(' ').length;
                }

                return wordCountOfCurrVal
                  ? accumulator + wordCountOfCurrVal
                  : accumulator;
              },
              0,
            );
          }
        },
      );

      textLength = wordCountOnlyReadables.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
    } catch (error) {
      console.log('[Error] richTextJson passed in, but no usable JSON');
    }
  } else {
    textLength = content?.split(' ').length;
  }

  return durationFromWordCount(textLength);
};

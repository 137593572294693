import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';
import noIndexSlugs from '@sprinklr/website/config/noIndexSlugs';
import noIndexPaths from '@sprinklr/website/config/noIndexPaths';
import { removeNodeLocaleFromSlug } from './removeNodeLocaleFromSlug';

export const shouldNeverIndex = (
  slug: string,
  domain?: string,
  resolvedPath?: string,
) => {
  if (!slug && !domain) return;
  const neverIndexByDomain = () => {
    if (isWindowDefined() || domain) {
      const currentHost = domain ? domain : window.location.host;
      const indexableDomains = [
        'sprinklr.com',
        'www.sprinklr.com',
        'blog.sprinklr.com',
      ];
      return !indexableDomains.includes(currentHost);
    }
    return false;
  };

  const neverIndexbyNoIndexSlugs = (slug: string) =>
    noIndexSlugs.includes(slug);

  const neverIndexbyNoIndexPaths = () => {
    const resolvedPathName = removeNodeLocaleFromSlug(resolvedPath);
    return noIndexPaths.some((noIndexPath: string) =>
      resolvedPathName.startsWith(noIndexPath),
    );
  };

  return (
    neverIndexByDomain() ||
    neverIndexbyNoIndexSlugs(slug) ||
    neverIndexbyNoIndexPaths()
  );
};

import {
  getPersistedTacticId,
  setPersistedTacticId,
  TACTIC_ID,
} from '@sprinklr/shared-lib/utils';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';

type TrafficType = {
  tacticId: string;
  name: string;
};

type Config = {
  searchEngines: string[];
  internalOrigins: string[];
  excludedInternalOrigins: string[];
  trafficTypes: TrafficType[];
};

const getTacticType = (config: Config, referrer?: string): string => {
  const { excludedInternalOrigins, internalOrigins, searchEngines } = config;

  if (!referrer) {
    return 'DIRECT';
  }

  if (searchEngines.some(domain => referrer.includes(domain))) {
    return 'ORGANIC';
  }

  const isInternalOrigin = internalOrigins.some(internalOrigin =>
    referrer.includes(internalOrigin),
  );
  const isExcludedInternalOrigin = excludedInternalOrigins.some(
    excludedOrigin => referrer.includes(excludedOrigin),
  );

  if (isInternalOrigin && !isExcludedInternalOrigin) {
    return 'INTERNAL_REFERRAL';
  }

  return 'EXTERNAL_REFERRAL';
};

const getTacticIdByReferrer = (config: Config, referrer?: string): string => {
  const { trafficTypes } = config;
  const tacticType = getTacticType(config, referrer);

  return trafficTypes.find(trafficType => trafficType.name === tacticType)
    ?.tacticId;
};

const getTacticId = (config: Config): string => {
  const referrer = document.referrer;
  const tacticId = new URLSearchParams(window.location.search).get(TACTIC_ID);
  return tacticId || getTacticIdByReferrer(config, referrer);
};

export const setTacticIdToSession = (config: Config) => {
  if (!isWindowDefined() || !config) return;

  const persistedTacticId = getPersistedTacticId();
  if (persistedTacticId) return persistedTacticId;

  const tacticId = getTacticId(config);
  setPersistedTacticId(tacticId);

  return tacticId;
};

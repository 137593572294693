/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  Fragment,
} from 'react';

const HeaderHeightContext = createContext<
  [number | null, (height: number) => void]
>([null, () => null]);

export const HeaderHeightContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [headerHeight, setHeaderHeight] = useState(null);
  return (
    <HeaderHeightContext.Provider value={[headerHeight, setHeaderHeight]}>
      {children}
    </HeaderHeightContext.Provider>
  );
};

export const HeaderHeightListener = (props: { children: ReactNode }) => {
  const [headerHeight] = useContext(HeaderHeightContext);
  const containerStyles = useMemo(
    () => ({
      ['--header-height' as string]: `${headerHeight}px`,
      display: 'contents',
    }),
    [headerHeight],
  );

  return headerHeight === null ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    <div sx={containerStyles}>{props.children}</div>
  );
};

export default HeaderHeightContext;

export const scrollToElement = (bpIndex, ref) => {
  // Magic numbers for responsive header
  let yOffset = 0;
  if (bpIndex === 0) yOffset = -76;
  if (bpIndex >= 1) yOffset = -135;

  const element = ref?.current;
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: 'smooth' });
};

const PRODUCT_CATEGORY_VS_SLUG = {
  Research: '/consumer-intelligence',
  Care: '/customer-service',
  Engagement: '/social-media-management',
  Platform: '/platform',
  'Marketing & Advertising': '/marketing-and-advertising',
};

export const formatProductCategory = category => {
  //check to see if there is only one category in array
  const formattedCategory =
    category instanceof Array && category.length === 1 ? category[0] : category;

  switch (formattedCategory) {
    case 'Platform & Technology':
    case 'Customer Stories':
    case 'Announcements':
    case 'Unified-CXM':
    case 'Unified-CXM Experience':
    case 'CXM':
    case 'Platform':
      return ['Platform'];
    case 'Research & Insights':
    case 'Research':
    case 'Modern Research':
    case 'Sprinklr Insights':
      return ['Research'];
    case 'Customer Care':
    case 'Modern Care':
    case 'Sprinklr Care':
      return ['Care'];
    case 'Social Engagement & Sales':
    case 'Sprinklr Social':
      return ['Engagement'];
    case 'Marketing & Advertising':
    case 'Modern Marketing':
    case 'Sprinklr Marketing':
      return ['Marketing & Advertising'];
    case null:
    case undefined:
    case 'Culture & Talent':
      return ['No Product Category'];
    //default returns any multiple length array w/o since form will automatically default arrays longer than 1 to default pages.
    default:
      return typeof formattedCategory === 'string'
        ? [formattedCategory]
        : formattedCategory;
  }
};

export const getPageSlug = ({ categorySlug, slug, productCategory }) => {
  if (categorySlug !== 'features') {
    return !!categorySlug ? `/${categorySlug}/${slug}` : slug;
  }
  const _productCategory = formatProductCategory(productCategory);
  const productCategorySlug = PRODUCT_CATEGORY_VS_SLUG[_productCategory] ?? '';
  return `/products${productCategorySlug}/${slug}`;
};

module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#1C6CFD","showSpinner":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sprinklr","short_name":"sprinklr","start_url":"/","background_color":"#fff","theme_color":"#1C6CFD","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3105ca52bb3502e13ef7cf334fffe9da"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","supportedLngs":["en","fr","jp","es","de","pt-br","gb","it","kr","sg","au","ar"],"languages":["en","fr","jp","es","de","pt-br","gb","it","kr","sg","au","ar"],"defaultLanguage":"en","nonExplicitSupportedLngs":false,"redirect":false,"i18nextOptions":{"redirect":false,"supportedLngs":["en","fr","jp","es","de","pt-br","gb","it","kr","sg","au","ar"],"defaultLanguage":"en","nonExplicitSupportedLngs":false,"lowerCaseLng":true,"debug":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/author/:author/page/:page","getLanguageFromPath":true,"excludeLanguages":["de","es","fr","gb","jp","pt-br","kr","au","sg","it"]},{"matchPath":"/:lang?/blog/category/:category?/page/:page/:anchor?","getLanguageFromPath":true,"excludeLanguages":["de","es","fr","gb","jp","pt-br","kr","au","sg","it"]},{"matchPath":"/:lang?/blog/category/:category","getLanguageFromPath":true,"excludeLanguages":["de","es","fr","gb","jp","pt-br","kr","au","sg","it"]},{"matchPath":"/:lang?/:slugPrefix?/:slugPrefix2?/:uid","getLanguageFromPath":true},{"matchPath":"/","getLanguageFromPath":true,"excludeLanguages":["it"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

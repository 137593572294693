import React from 'react';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

export const resolveHomePageURL = () => {
  let homePageURL = '/';
  let { language } = React.useContext(I18nextContext);

  if (language === 'en' || language === 'it') {
    language = '';
  }

  language && (homePageURL = `/${language}/`);

  return homePageURL;
};

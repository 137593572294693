// never index regardless of the Contentful flag
module.exports = [
  '404',
  'unsubscribe',
  'unsubscribe-thank-you',
  'thanks-for-your-feedback',
  'app-login-new',
  'app-login-new-2',
  'app-login-new-old',
  'app-login-internal',
  'hyperspace-login-internal',
  '/iframe/app-login-new',
  '/iframe/app-login-internal',
  '/cheq-do-no-tag',
  'home-no-tags',
];

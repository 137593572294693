/**
 * For non external paths, returns only a trailing slash
 *
 * @param {string} path
 * @param {string} host
 * @param {boolean} removeLeadingSlash true
 * @param {boolean} addLeadingSlash true
 * @param {boolean} addTrailingSlash false
 * @param {boolean} removeTrailingSlash false
 * @param {boolean} removeAllSlashes false
 * @param {boolean} removeDoubleSlashes false
 * @param {boolean} addHost true
 */
export const normalizePathSlashes = ({
  path,
  host = '',
  addLeadingSlash = true,
  removeLeadingSlash = false,
  addTrailingSlash = true,
  removeAllSlashes = false,
  removeTrailingSlash = false,
  removeDoubleSlashes = false,
}) => {
  const isExternal = new RegExp('^(//|https?)');

  if (removeAllSlashes) {
    removeTrailingSlash = true;
    removeLeadingSlash = true;
    addTrailingSlash = false;
    addLeadingSlash = false;
  }

  if (isExternal.test(path)) {
    host = '';
    addLeadingSlash = false;
    addTrailingSlash = false;
    removeTrailingSlash = false;
    removeLeadingSlash = false;

    // force trailing slash only for sprinklr.com
    ~path.indexOf('www.sprinklr.com') && (addTrailingSlash = true);
  }

  if (removeDoubleSlashes) {
    addLeadingSlash = false;
    addTrailingSlash = false;
  }

  addLeadingSlash && (path = path.replace(/^\/?/, '/'));
  addTrailingSlash && (path = path.replace(/\/?$/, '/'));
  removeLeadingSlash && (path = path.replace(/^\/?/, ''));
  removeTrailingSlash && (path = path.replace(/\/$/, ''));
  removeDoubleSlashes &&
    (path = path.replace(/\/\//g, (match, index) =>
      index <= 6 ? match : '/',
    ));

  if (host) {
    host = host.replace(/\/$/, '');
    path = `${host}${path}`;
  }

  return path;
};

//there is another copy of this in packages/website/utils/sitemapUtils.ts for the gatsby config. If making changes to this, you must make changes to the other.
export const resolveLocale = (
  nodeLocaleOrLanguage,
  upperCaseLocale = false,
  defaultLocale = 'default',
) => {
  let resolvedLocaleOrLanguage = '';
  switch (nodeLocaleOrLanguage.toLowerCase()) {
    case 'en':
      resolvedLocaleOrLanguage = 'en-us';
      break;
    case 'es':
      resolvedLocaleOrLanguage = 'es-001';
      break;
    case 'gb':
      resolvedLocaleOrLanguage = 'en-gb';
      break;
    case 'au':
      resolvedLocaleOrLanguage = 'en-au';
      break;
    case 'fr':
      resolvedLocaleOrLanguage = 'fr-fr';
      break;
    case 'jp':
      resolvedLocaleOrLanguage = 'ja-jp';
      break;
    case 'de':
      resolvedLocaleOrLanguage = 'de-de';
      break;
    case 'sg':
      resolvedLocaleOrLanguage = 'en-sg';
      break;
    case 'kr':
      resolvedLocaleOrLanguage = 'ko';
      break;
    case 'en-us':
      resolvedLocaleOrLanguage = '';
      break;
    case 'es-001':
      resolvedLocaleOrLanguage = 'es';
      break;
    case 'en-gb':
      resolvedLocaleOrLanguage = 'gb';
      break;
    case 'en-au':
      resolvedLocaleOrLanguage = 'au';
      break;
    case 'fr-fr':
      resolvedLocaleOrLanguage = 'fr';
      break;
    case 'ja-jp':
      resolvedLocaleOrLanguage = 'jp';
      break;
    case 'pt-br':
      resolvedLocaleOrLanguage = 'pt-br';
      break;
    case 'de-de':
      resolvedLocaleOrLanguage = 'de';
      break;
    case 'ko':
      resolvedLocaleOrLanguage = 'kr';
      break;
    case 'en-sg':
      resolvedLocaleOrLanguage = 'sg';
      break;
    case 'english (en-us)':
      resolvedLocaleOrLanguage = '';
      break;
    case 'french (fr-fr)':
      resolvedLocaleOrLanguage = 'fr';
      break;
    case 'portuguese (pt-br)':
      resolvedLocaleOrLanguage = 'pt-BR';
      break;
    case 'spanish (es-001)':
      resolvedLocaleOrLanguage = 'es';
      break;
    case 'german (de-de)':
      resolvedLocaleOrLanguage = 'de';
      break;
    case 'english (en-gb)':
      resolvedLocaleOrLanguage = 'gb';
      break;
    case 'english (en-au)':
      resolvedLocaleOrLanguage = 'au';
      break;
    case 'japanese (ja-jp)':
      resolvedLocaleOrLanguage = 'jp';
      break;
    case 'korean (ko)':
      resolvedLocaleOrLanguage = 'kr';
      break;
    case 'english (en-sg)':
      resolvedLocaleOrLanguage = 'sg';
      break;
    case 'arabic (ar)':
      resolvedLocaleOrLanguage = 'ar';
      break;
    case 'ar':
      resolvedLocaleOrLanguage = 'ar';
      break;
    case 'it':
      resolvedLocaleOrLanguage = 'it';
      break;

    default:
      resolvedLocaleOrLanguage = '';
      break;
  }

  const langLocale = resolvedLocaleOrLanguage.split('-');
  upperCaseLocale &&
    langLocale[1] &&
    (langLocale[1] = langLocale[1].toUpperCase());
  resolvedLocaleOrLanguage = langLocale.join('-');

  defaultLocale !== 'default' &&
    !resolvedLocaleOrLanguage &&
    (resolvedLocaleOrLanguage = defaultLocale);

  return resolvedLocaleOrLanguage;
};

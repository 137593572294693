import React from 'react';

import {
  errorBoundaryFallback,
  errorBoundaryHandler,
} from '@sprinklr/shared-lib/utils';
import { ErrorBoundary } from 'react-error-boundary';
import { HeaderHeightContextProvider } from '@sprinklr/shared-lib/context/HeaderHeightContext';

export const Page = ({ element, path, pageContext }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  return (
    <ErrorBoundary
      FallbackComponent={errorBoundaryFallback}
      onError={errorBoundaryHandler}
    >
      <HeaderHeightContextProvider>{element}</HeaderHeightContextProvider>
    </ErrorBoundary>
  );
};

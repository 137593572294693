import { isWindowDefined } from './isWindowDefined';

export const TACTIC_ID = 'tactic_id';

export const getPersistedTacticId = (): string => {
  if (!isWindowDefined()) return '';
  const tacticId = window.sessionStorage.getItem(TACTIC_ID);
  return tacticId || '';
};

export const setPersistedTacticId = (tacticId: string): void => {
  if (!isWindowDefined()) return;
  window.sessionStorage.setItem(TACTIC_ID, tacticId);
};

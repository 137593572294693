/**
 * filters data by passed in nodeLocale
 * if no nodeLocale defaults to en-US
 * overrides item nodeLocale with localeSpoofField array, if exists
 *
 * @param {data} any[]
 * @param {string} locale en-US
 * @param {localeSpoofField} string
 */
import { resolveLocale } from '@sprinklr/shared-lib/utils';

export const filterDataByLocale = (
  data: any[],
  locale = 'en-US',
  localeSpoofField = '',
  ignoreTranslations = false,
  isTemplate = false,
) => {
  if (!data[0] || !locale) return data;

  if (locale === 'it-IT') locale = 'en-US';

  return data.filter(item => {
    let { translations } = item;
    translations = !!translations?.languagesToRenderIn?.length
      ? translations?.languagesToRenderIn
      : translations;

    if (!ignoreTranslations) {
      if (!translations?.length) return false;
    }

    // support nested nodeLocale
    let { nodeLocale = 'en-us' } = item;
    item.node && (nodeLocale = item.node.nodeLocale);
    const lowerCaseNodeLocale = nodeLocale?.toLowerCase();
    const itemInLocale = lowerCaseNodeLocale === locale.toLowerCase();

    //Simple matching case in locale
    if (ignoreTranslations && itemInLocale) return true;

    const resolvedNodeLocale = resolveLocale(locale, false, 'en-US');
    const itemInTranslations = translations?.some(
      translation =>
        resolveLocale(translation, false, 'en-US') === resolvedNodeLocale,
    );

    const showInOtherLocales = isTemplate
      ? item?.pageContent?.templates[0][localeSpoofField]?.find(locale =>
          locale.toLowerCase().includes(lowerCaseNodeLocale),
        )
      : item[localeSpoofField]?.some(
          locale =>
            resolveLocale(locale, false, 'en-US') === resolvedNodeLocale,
        );

    // Add nodeLocaleOverride attribue which can be used to direct to the correct locale for the card's link if page is not built by translations
    if (!itemInTranslations && showInOtherLocales)
      item.nodeLocaleOverride = resolveLocale(
        translations[0],
        false,
        'en-US',
      ).toLowerCase();
    if (item.nodeLocaleOverride?.length === 2)
      item.shouldNotResolveLocale = true;

    //when translations.length === 0 there
    if (!!showInOtherLocales)
      return (
        !!itemInLocale && (!localeSpoofField?.length || !!showInOtherLocales)
      );
    else
      return !!itemInLocale && (!translations?.length || !!itemInTranslations);
  });
};

export const reduceObjValues = (
  obj = {},
  cache = {},
  opts = { exclude: [] },
) => {
  if (!obj) return;

  const filteredKeys = Object.keys(obj).filter(
    key => !opts.exclude.includes(key),
  );

  const objectValues = filteredKeys.reduce((acc, curr) => {
    if (!Array.isArray(obj[curr]) && typeof obj[curr] === 'object') {
      return reduceObjValues({ ...acc, ...obj[curr] }, cache, opts);
    }

    if (Array.isArray(obj[curr])) {
      obj[curr].forEach((item, index) => {
        obj[curr][index] = reduceObjValues({ ...acc, ...item }, cache, opts);
      });
    }
    if (curr !== 'sys') acc[curr] = obj[curr];
    return acc;
  }, {});

  opts.exclude.forEach(
    passthrough => (objectValues[passthrough] = obj[passthrough]),
  );

  return {
    ...objectValues,
    ...cache,
  };
};

import React, { useEffect, ReactElement, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { setTacticIdToSession } from '../../utils/setTacticIdToSession';
import { TacticIdContext } from '../context/TacticIdContext';

const TRAFFIC_DATA_QUERY = graphql`
  {
    allContentfulTrafficData {
      nodes {
        searchEngines
        internalOrigins
        excludedInternalOrigins
        trafficTypes {
          tacticId
          name
        }
      }
    }
  }
`;

const RootWrapper = ({ element }): ReactElement => {
  const [tacticId, setTacticId] = useState('');

  const { allContentfulTrafficData } = useStaticQuery(TRAFFIC_DATA_QUERY) || {};
  const trafficData = allContentfulTrafficData?.nodes?.[0];

  useEffect(() => {
    setTacticId(setTacticIdToSession(trafficData));
  }, [trafficData]);

  return (
    <TacticIdContext.Provider value={tacticId}>
      {element}
    </TacticIdContext.Provider>
  );
};

export default RootWrapper;
